import React from "react";
import Services from "./Services";
import CampaignIcon from "@mui/icons-material/Campaign";
import ShareIcon from "@mui/icons-material/Share";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import CodeIcon from "@mui/icons-material/Code";

const ServicesCardList = () => {
  const servicesData = [
    {
      icon: <CampaignIcon fontSize="" />,
      title: "Influence Marketing",
      subTitle:
        "Harness the power of influential voices to boost your brand’s visibility and credibility. Our influence marketing services connect you with key industry influencers who can effectively promote your products or services. We strategize and manage influencer campaigns that resonate with your target audience, driving engagement and conversions.",
    },
    {
      icon: <ShareIcon fontSize="" />,
      title: "Social Media Marketing",
      subTitle:
        "Build a robust online presence with our social media marketing services. We create and execute tailored social media strategies that enhance your brand’s reach and engagement on platforms like Facebook, Instagram, Twitter, LinkedIn, and more. From content creation to analytics, we manage all aspects to ensure your brand stands out in the crowded social media landscape.",
    },

    {
      icon: <ContactPageIcon fontSize="" />,
      title: "Lead Generation",
      subTitle:
        "Fuel your sales pipeline with high-quality leads. Our lead generation services employ advanced techniques to identify and capture potential customers interested in your offerings. We use a mix of digital marketing, content marketing, and targeted campaigns to generate leads that convert into loyal customers, driving your business growth.",
    },
    {
      icon: <WorkOutlineIcon fontSize="" />,
      title: "Outsourcing",
      subTitle:
        "Optimize your operations and reduce costs with our outsourcing solutions. We provide skilled professionals and comprehensive services for tasks such as customer support, IT services, and administrative functions. By outsourcing to Curiostuff TechLab, you can focus on your core business activities while we handle the rest.",
    },
    {
      icon: <AppRegistrationIcon fontSize="" />,
      title: "Existing Application Services ",
      subTitle:
        "Maximize the value of your existing applications with our maintenance and enhancement services. We offer ongoing support to ensure your applications run smoothly and efficiently. Our team can also enhance functionality, update features, and integrate new technologies to keep your applications current and competitive.",
    },
    {
      icon: <LightbulbIcon fontSize="" />,
      title: "Web Application Development",
      subTitle:
        "Transform your ideas into powerful web applications with our development services. We design and develop custom web applications tailored to your business needs. Our team ensures that your web applications are user-friendly, scalable, and secure, providing a seamless experience for your users.",
    },

    {
      icon: <LightbulbIcon fontSize="" />,
      title: "Mobile Application Development ",
      subTitle:
        "Reach your customers on the go with our mobile application development services. We create intuitive and engaging mobile apps for iOS and Android platforms. Our development process focuses on delivering high-performance, feature-rich mobile apps that meet your business objectives and user expectations.",
    },
    {
      icon: <CodeIcon fontSize="" />,
      title: "Desktop Application Development",
      subTitle:
        "Enhance your business operations with our desktop application development services. We build robust and efficient desktop applications that cater to your specific requirements. Our support services ensure that your applications are maintained, updated, and performing optimally at all times.",
    },
  ];
  return (
    <React.Fragment>
      <div style={{ overflow: "auto" }}>
        <Services servicesData={servicesData} />
      </div>
    </React.Fragment>
  );
};

export default ServicesCardList;
