import React from "react";
import { Box, Grid, ImageList, ImageListItem, Typography } from "@mui/material";
import About1 from "../../assets/images/about/about1.jpg";
import About2 from "../../assets/images/about/about2.jpg";
import About3 from "../../assets/images/about/about3.jpg";
import About4 from "../../assets/images/about/about4.jpg";

const About = () => {
  const itemData = [
    {
      img: About1,
      title: "Breakfast",
    },
    {
      img: About2,
      title: "Burger",
    },
    {
      img: About3,
      title: "Camera",
    },
    {
      img: About4,
      title: "Coffee",
    },
  ];

  return (
    <Box p={{ xs: 4, md: 8 }} id="about" >
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12} md={6} position="relative">
          <Box
            sx={{
              position: "relative",
              padding: 3,
              background: "#fff",
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <ImageList
              sx={{
                width: "100%",
                height: "auto",
                overflow: "hidden",
              }}
              cols={2}
              rowHeight={200}
              gap={20}
            >
              {itemData.map((item) => (
                <ImageListItem key={item.img} sx={{ padding: 1 }}>
                  <img
                    style={{
                      border: "2px solid #000",
                      borderRadius: "12px",
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    src={item.img}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="h3"
            sx={{
              fontSize: { xs: "2rem", md: "2.5rem" },
              fontWeight: "bold",
              marginBottom: 2,
            }}
          >
            About Us
          </Typography>
          <Typography
            variant="body1"
            sx={{marginBottom: 2 }}
          >
            Welcome to Curiostuff TechLab, where curiosity meets innovation to
            transform businesses through cutting-edge software technology.
            Founded on the principles of creativity, excellence, and
            forward-thinking, we specialize in providing comprehensive software
            solutions that drive business development and growth.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              marginTop: 2,
              fontWeight: "bold",
            }}
          >
            Our Mission
          </Typography>
          <Typography
            variant="body1"
            sx={{  marginBottom: 2 }}
          >
            At Curiostuff TechLab, our mission is to empower businesses by
            turning innovative ideas into practical, scalable, and efficient
            software solutions. We believe in the power of technology to solve
            complex business challenges and create new opportunities for
            success. Our goal is to help our clients stay ahead in an
            ever-evolving digital landscape.
          </Typography>
          <Typography
            variant="h6"
            sx={{
              marginTop: 2,
              fontWeight: "bold",
            }}
          >
            Our Approach
          </Typography>
          <Typography
            variant="body1"
            sx={{ marginBottom: 2 }}
          >
            At Curiostuff TechLab, we are driven by a passion for innovation and
            a commitment to excellence. Our approach is client-centric, focusing
            on understanding your unique challenges and goals. We combine
            technical expertise with creative thinking to deliver solutions that
            not only meet your needs but exceed your expectations.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default About;
