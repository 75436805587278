import React from 'react'
import BackHeader from '../backHeader/BackHeader'
import { Typography, Container, Grid, Box, Paper } from "@mui/material";
const InfluenceMarketing = () => {
  return (
    <div style={{marginTop:"100px"}}>
     <BackHeader/>
     <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h2" gutterBottom>
        Influence Marketing
      </Typography>
      <Typography variant="body1" paragraph>
        Harness the power of influential voices to boost your brand’s visibility and credibility. Our influence marketing services connect you with key industry influencers who can effectively promote your products or services. We strategize and manage influencer campaigns that resonate with your target audience, driving engagement and conversions.
      </Typography>
      <Grid container spacing={3}>
        {/* Left Content */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            {/* Add any additional content specific to influence marketing */}
            <Typography variant="h4" gutterBottom>
              Why Influence Marketing?
            </Typography>
            <Typography variant="body1" paragraph>
              Influence marketing is a powerful strategy to reach your target audience through trusted voices. By collaborating with influencers in your industry, you can amplify your brand's message and build credibility.
            </Typography>
            {/* Add more content as needed */}
          </Paper>
        </Grid>
        {/* Right Content */}
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            {/* Add any additional content specific to influence marketing */}
            <Typography variant="h4" gutterBottom>
              Our Approach
            </Typography>
            <Typography variant="body1" paragraph>
              We work closely with you to identify the right influencers for your brand and develop tailored campaigns that align with your goals. Our team manages every aspect of the influencer marketing process, from strategy to execution.
            </Typography>
            {/* Add more content as needed */}
          </Paper>
        </Grid>
      </Grid>
    </Container>
    </div>
  )
}

export default InfluenceMarketing
