import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Typography,
  Button,
  Icon,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ServicesMain from "./ServicesMain";
import { Link } from "react-router-dom";

const Services = ({ servicesData }) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const servicesLinks = [
    "influence-marketing",
    "socialmedia-marketing",
    "lead-generation",
    "outsourcing",
    "existing-application-services",
    "web-application-development",
    "mobile-application-development",
    "desktop-application-development"
  ];

  return (
    <Box p={{ xs: 2, md: 8 }} sx={{ bgcolor: "transparent" }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item sx={{ mb: "20px" }}>
          <ServicesMain />
        </Grid>
        {servicesData.map((serviceData, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Card
              sx={{
                height: "100%",
                backgroundColor: "#f5f5f5",
                color: "#000",
                borderRadius: "14px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <CardContent>
                <Icon
                  color="#000"
                  sx={{
                    mb: 2,
                    transform: isXs ? "scale(1.5)" : "scale(1.5)",
                    mr: isXs ? 1 : 2,
                    // Adjust icon color
                    borderRadius: "12px",
                    backgroundColor: "#ffffff",
                    justifyContent: "center",
                  }}
                >
                  {serviceData.icon}
                </Icon>
                <Typography variant="h5" component="h2" gutterBottom>
                  {serviceData.title}
                </Typography>
                <Typography variant="body1" component="p" sx={{ mb: 2 }}>
                  {serviceData.subTitle.slice(0, 100)}...
                </Typography>
                <Button
                  size="small"
                  component={Link} // Use Link component from React Router
                  to={`/${servicesLinks[index]}`}
                  color="secondary" // Adjust button color
                  sx={{ textTransform: "none", fontWeight: 600 }} // Adjust button style
                >
                  Read More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

Services.propTypes = {
  servicesData: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Services;
