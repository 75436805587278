import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  Divider,
  TextField,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Facebook, Twitter, LinkedIn, Instagram } from "@mui/icons-material";
import StyledButton from "../../components/button/StyledButton";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Footer = () => {
  const theme = createTheme({
    components: {
      // custom theme overrides here
    },
  });

  const socialLinks = [
    { icon: <Facebook />, url: "https://facebook.com" },
    { icon: <Twitter />, url: "https://twitter.com" },
    { icon: <LinkedIn />, url: "https://linkedin.com" },
    { icon: <Instagram />, url: "https://instagram.com" },
  ];

  return (
    <Box
      id="footer"

      sx={{
        color: "#000000",
        backgroundColor: "#f0f0f",
        padding: "20px 0",
        mt: 5,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: { xs: "2rem", md: "2rem" }, fontWeight: "bold" }}
            >
              Stay with Us To Get
            </Typography>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ fontSize: { xs: "2rem", md: "2rem" }, fontWeight: "200" }}
            >
              More Info
            </Typography>
            <Typography variant="body1" paragraph>
              At Curiostuff TechLab, we are dedicated to providing innovative
              solutions that drive your business forward. Our comprehensive
              range of services ensures that we can meet all your technology and
              marketing needs, helping you achieve sustainable growth and
              success. Partner with us to experience the synergy of advanced
              technology and strategic business development.
            </Typography>
            <ThemeProvider theme={theme}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: 2,
                  mt: 2,
                }}
              >
                <TextField
                  variant="outlined"
                  label="Enter Your Email / Mobile Number"
                  sx={{
                    width: { xs: "100%", sm: "300px", md: "400px" },
                    border: "none",
                  }}
                />
                <StyledButton>Connect Now</StyledButton>
              </Box>
            </ThemeProvider>
          </Grid>

          <Grid container item xs={12} spacing={3} sx={{ mt: "10px" }}>
            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
              <Typography variant="h6" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body2">
                Corporate Office Address:
                <br />
                No.154, 1st Floor Royal Space,
                <br />
                Rajiv Gandhi Nagar, Sector 7,
                <br />
                HSR Layout, Bengaluru,
                <br />
                Karnataka 560102, India.
              </Typography>
            </Grid>

            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
              <Typography variant="h6" gutterBottom>
                Quick Links
              </Typography>

              <ScrollLink
                to="about"
                smooth={true}
                offset={-70}
                duration={500}
                spy={true}
                activeClass="active"
              >
                <Link
                  color="inherit"
                  variant="body2"
                  display="block"
                  gutterBottom
                >
                  About Us
                </Link>
              </ScrollLink>

              <ScrollLink
                to="services"
                smooth={true}
                offset={-70}
                duration={500}
                spy={true}
                activeClass="active"
              >
                <Link
                  color="inherit"
                  variant="body2"
                  display="block"
                  gutterBottom
                >
                  Services
                </Link>
              </ScrollLink>
              <Link
                component={RouterLink}
                to="/contact"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
              >
                Contact
              </Link>
              <Link
                component={RouterLink}
                to="/privacy-policy"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
              >
                Privacy Policy
              </Link>
              <Link
                component={RouterLink}
                to="/terms"
                color="inherit"
                variant="body2"
                display="block"
                gutterBottom
              >
                Terms & Conditions
              </Link>
            </Grid>

            <Grid item xs={12} md={4} textAlign={{ xs: "center", md: "left" }}>
              <Typography variant="h6" gutterBottom>
                Follow Us
              </Typography>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "start" }}
              >
                {socialLinks.map((social) => (
                  <Link
                    key={social.url}
                    href={social.url}
                    color="inherit"
                    variant="body2"
                    display="block"
                    sx={{ mr: 2 }}
                  >
                    {social.icon}
                  </Link>
                ))}
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ backgroundColor: "#fff", marginY: 3 }} />

          <Box textAlign="center" pt={2} pb={2}>
            <Typography variant="body2">
              © {new Date().getFullYear()} Your Company. All rights reserved.
              Created by
             
            </Typography>
          </Box>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
