import React from "react";
import { Typography, Container, Box, Link, List, ListItem } from "@mui/material";
import BackHeader from "../backHeader/BackHeader";

const Policy = () => {
  return (
    <Container>
      <BackHeader />
      <Box my={4}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy for Curiostuff Techlab Pvt Ltd
        </Typography>

        <Typography variant="body1" paragraph>
          At Curiostuff Techlab Pvt Ltd, accessible from www.Curiostuff.com, one
          of our main priorities is the privacy of our visitors. This Privacy
          Policy document contains types of information that is collected and
          recorded by Curiostuff Techlab Pvt Ltd and how we use it.
        </Typography>

        <Typography variant="body1" paragraph>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Log Files
        </Typography>
        <Typography variant="body1" paragraph>
          Curiostuff Techlab Pvt Ltd follows a standard procedure of using log
          files. These files log visitors when they visit websites. All hosting
          companies do this and a part of hosting services' analytics. The
          information collected by log files include internet protocol (IP)
          addresses, browser type, Internet Service Provider (ISP), date and time
          stamp, referring/exit pages, and possibly the number of clicks. These
          are not linked to any information that is personally identifiable. The
          purpose of the information is for analyzing trends, administering the
          site, tracking users' movement on the website, and gathering demographic
          information. Our Privacy Policy was created with the help of the{" "}
          <Link href="https://www.privacypolicyonline.com/privacy-policy-generator/" target="_blank" rel="noopener">
            Privacy Policy Generator
          </Link>.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Cookies and Web Beacons
        </Typography>
        <Typography variant="body1" paragraph>
          Like any other website, Curiostuff Techlab Pvt Ltd uses "cookies". These
          cookies are used to store information including visitors' preferences,
          and the pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing our
          web page content based on visitors' browser type and/or other
          information.
        </Typography>
        <Typography variant="body1" paragraph>
          For more general information on cookies, please read{" "}
          <Link href="https://www.privacypolicyonline.com/what-are-cookies/" target="_blank" rel="noopener">
            the "Cookies" article from the Privacy Policy Generator
          </Link>.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Our Advertising Partners
        </Typography>
        <Typography variant="body1" paragraph>
          Some of advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising partners
          has their own Privacy Policy for their policies on user data. For easier
          access, we hyperlinked to their Privacy Policies below.
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body1">
              Google:{" "}
              <Link href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener">
                https://policies.google.com/technologies/ads
              </Link>
            </Typography>
          </ListItem>
        </List>

        <Typography variant="h5" gutterBottom>
          Privacy Policies
        </Typography>
        <Typography variant="body1" paragraph>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of Curiostuff Techlab Pvt Ltd.
        </Typography>
        <Typography variant="body1" paragraph>
          Third-party ad servers or ad networks use technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on Curiostuff Techlab Pvt Ltd,
          which are sent directly to users' browser. They automatically receive
          your IP address when this occurs. These technologies are used to measure
          the effectiveness of their advertising campaigns and/or to personalize
          the advertising content that you see on websites that you visit.
        </Typography>
        <Typography variant="body1" paragraph>
          Note that Curiostuff Techlab Pvt Ltd has no access to or control over
          these cookies that are used by third-party advertisers.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Third Party Privacy Policies
        </Typography>
        <Typography variant="body1" paragraph>
          Curiostuff Techlab Pvt Ltd's Privacy Policy does not apply to other
          advertisers or websites. Thus, we are advising you to consult the
          respective Privacy Policies of these third-party ad servers for more
          detailed information. It may include their practices and instructions
          about how to opt-out of certain options.
        </Typography>
        <Typography variant="body1" paragraph>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management with
          specific web browsers, it can be found at the browsers' respective
          websites.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Children's Information
        </Typography>
        <Typography variant="body1" paragraph>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </Typography>
        <Typography variant="body1" paragraph>
          Curiostuff Techlab Pvt Ltd does not knowingly collect any Personal
          Identifiable Information from children under the age of 13. If you think
          that your child provided this kind of information on our website, we
          strongly encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Online Privacy Policy Only
        </Typography>
        <Typography variant="body1" paragraph>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in Curiostuff Techlab Pvt Ltd. This policy is not
          applicable to any information collected offline or via channels other
          than this website.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Consent
        </Typography>
        <Typography variant="body1" paragraph>
          By using our website, you hereby consent to our Privacy Policy and agree
          to its Terms and Conditions.
        </Typography>
      </Box>
    </Container>
  );
};

export default Policy;
