import React from "react";
import BackHeader from "../backHeader/BackHeader";
import { Typography, Container, Grid, Box, Paper } from "@mui/material";
const SocialMediaMarketing = () => {
  return (
    <div style={{marginTop:"100px"}}>
      <BackHeader />
    
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography variant="h2" gutterBottom>
          Social Media Marketing
        </Typography>
        <Typography variant="body1" paragraph>
          Build a robust online presence with our social media marketing
          services. We create and execute tailored social media strategies that
          enhance your brand’s reach and engagement on platforms like Facebook,
          Instagram, Twitter, LinkedIn, and more. From content creation to
          analytics, we manage all aspects to ensure your brand stands out in
          the crowded social media landscape.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h4" gutterBottom>
                Why Social Media Marketing?
              </Typography>
              <Typography variant="body1" paragraph>
                Social media marketing allows businesses to connect with their
                audience on a personal level, build brand loyalty, and drive
                traffic to their website. With the right strategy, businesses
                can increase brand awareness and generate leads.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h4" gutterBottom>
                Our Approach
              </Typography>
              <Typography variant="body1" paragraph>
                We start by understanding your business goals and target
                audience. Then, we develop a comprehensive social media strategy
                that includes content creation, posting schedules, community
                management, and analytics. Our goal is to maximize your brand's
                presence and engagement on social media platforms.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SocialMediaMarketing;
