import { useNavigate } from "react-router-dom";
import { AppBar, Box, Typography, Toolbar, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";


const BackHeader = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBack = () => {
    // Navigate to the home page when the back button is clicked
    navigate("/");
  };

  return (
    <AppBar
     
      sx={{
        background: "white",
        color: "#000",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        
      }}
    >
      <Toolbar  position="sticky">
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="flex" alignItems="center">
            <Typography variant={isXs ? "h6" : "h4"} sx={{ pl: isXs ? 1 : 2 }}>
              Curiostuff TechLab
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton edge="end" color="inherit" aria-label="back" onClick={handleBack}>
              <ArrowBack />
            </IconButton>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default BackHeader;
