import React from 'react'
import BackHeader from '../backHeader/BackHeader'
import { Typography, Container, Grid, Paper } from "@mui/material";
const MobileApplicationDevelopment = () => {
  return (
    <div style={{marginTop:"100px"}}>
      <BackHeader/>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h2" gutterBottom>
        Mobile Application Development
      </Typography>
      <Typography variant="body1" paragraph>
        Reach your customers on the go with our mobile application development services. We create intuitive and engaging mobile apps for iOS and Android platforms. Our development process focuses on delivering high-performance, feature-rich mobile apps that meet your business objectives and user expectations.
      </Typography>
      <Grid container spacing={3}>
    
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
              iOS App Development
            </Typography>
            <Typography variant="body1" paragraph>
              We specialize in iOS app development, leveraging the latest technologies and design trends to create seamless experiences for Apple users. Our iOS apps are optimized for performance and reliability, ensuring a smooth user experience on all Apple devices.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
              Android App Development
            </Typography>
            <Typography variant="body1" paragraph>
              Our Android app development services cater to the diverse Android ecosystem, delivering apps that are compatible with a wide range of devices and screen sizes. We prioritize performance and usability to ensure that your Android app stands out on the Google Play Store.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
    </div>
 
  )
}

export default MobileApplicationDevelopment