import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Rating,
  useMediaQuery,
  Slide,
  IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

const Testimonials = () => {
  const testimonialsData = [
    {
      name: "Prashun Shetty, CEO of Tagskills",
      rating: 5,
      description: "Curiostuff Techlab has been a game-changer for our company. Their innovative solutions and dedicated support have significantly improved our operational efficiency. We couldn't be happier with the results!",
    },
    {
      name: "Swapna K, CTO of Invayas",
      rating: 5,
      description: "Working with Curiostuff Techlab has been an absolute pleasure. Their team's expertise and commitment to excellence are evident in every project they undertake. They've helped us stay ahead of the curve in our industry.",
    },
    {
      name: "Sukesh, CEO of Airexpe ",
      rating: 5,
      description: "Choosing Curiostuff Techlab was one of the best decisions we've made. Their expertise in technology and commitment to customer satisfaction are unmatched. They have truly exceeded our expectations.",
    },
    {
      name: "Sankalp Agarwal, CEO of Agasinc Technologies",
      rating: 5,
      description: "The support and expertise we receive from Curiostuff Techlab are unparalleled. Their team's dedication to our success is evident in every interaction. We're proud to call them our trusted partner.",
    },
    {
      name: "Royal Sequeira, CEO of Coralframes",
      rating: 5,
      description: "Curiostuff Techlab has provided us with top-notch service and cutting-edge technology solutions. Their team is knowledgeable, responsive, and always willing to go the extra mile. We highly recommend them!.",
    },
  ];

  const isMobile = useMediaQuery("(max-width:600px)");

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonialsData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const groupSize = isMobile ? 1 : 3; // Number of cards per row adjusted for mobile view

  return (
    <Box p={6} >
      <Typography variant="h3" textAlign="center" mb={2}  sx={{ fontSize: { xs: "2rem", md: "2rem" }, fontWeight: 'bold',}}>
        What Our Trusted Clients Say
      </Typography>
      <Typography variant="body1" textAlign="center" mb={4}>
      At Curiostuff Techlab, we are dedicated to providing innovative technology solutions and outstanding customer service. Our expert team works tirelessly to ensure our clients' success, delivering results that exceed expectations. Here’s what our valued clients have to say about their experiences with us:
      </Typography>
      <Grid container spacing={isMobile ? 2 : 4} justifyContent="center">
        {testimonialsData
          .slice(currentIndex, currentIndex + groupSize)
          .map((testimonial, index) => (
            <Grid key={index} item xs={12} sm={4}>
              <Slide
                direction="left"
                in={true}
                mountOnEnter
                unmountOnExit
                timeout={500}
              >
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {testimonial.name}
                    </Typography>
                    <Rating
                      value={testimonial.rating}
                      precision={0.5}
                      readOnly
                    />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {testimonial.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Slide>
            </Grid>
          ))}
      </Grid>
      <Grid container justifyContent="center" mt={2}>
        <Grid item>
          <IconButton onClick={handlePrev}>
            <ArrowBack />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton onClick={handleNext}>
            <ArrowForward />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Testimonials;
