import { Grid, Box, Typography, useMediaQuery } from "@mui/material";
import { useSpring, animated } from "react-spring";
import homePageImage from "../../assets/images/homePage/homePage.png";
import StyledButton from "../../components/button/StyledButton";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const Home = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const textAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 1000 },
  });

  const imageAnimation = useSpring({
    from: { opacity: 0, transform: "scale(0.5)" },
    to: { opacity: 1, transform: "scale(1)" },
    config: { duration: 1000 },
  });

  return (
    <Box
      p={{ xs: 2, md: 8 }}
      sx={{ bgcolor: "transparent", overflow: "auto", mt: isMobile ? "56px" : "100px" }}
    >
      <Grid container spacing={isMobile ? 2 : 8} justifyContent={"center"}>
        <Grid item xs={12} md={6}>
          <animated.div style={textAnimation}>
            <Box mb={isMobile ? 2 : 6}>
              <Typography variant={isMobile ? "h3" : "h1"} sx={{ color: "#333333", zIndex: 1 }}>
                Catalyzing Growth through Technology
              </Typography>
            </Box>
            <Typography mb={isMobile ? 2 : 6}>
              We use technology as a catalyst to accelerate your business growth.
              Our solutions are designed to maximize efficiency and drive
              progress. Partner with us to unlock new opportunities and achieve
              your full potential.
            </Typography>
            <StyledButton endIcon={<ArrowOutwardIcon />} variant="contained">
              Get Started
            </StyledButton>
          </animated.div>
        </Grid>
        <Grid item xs={12} md={6}>
          <animated.div style={imageAnimation}>
            <Box
              component="img"
              src={homePageImage}
              alt="Curiostuff Home"
              sx={{
                width: "100%",
                borderRadius: isMobile ? "0" : "4%",
                border: isMobile ? "none" : "5px solid #fff",
              }}
            />
          </animated.div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
