import React from 'react'
import BackHeader from '../backHeader/BackHeader'
import { Typography, Container, Grid, Box, Paper } from "@mui/material";

const LeadGeneration = () => {
  return (
    <div style={{marginTop:"100px"}}> 
      <BackHeader/>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h2" gutterBottom>
        Lead Generation
      </Typography>
      <Typography variant="body1" paragraph>
        Fuel your sales pipeline with high-quality leads. Our lead generation services employ advanced techniques to identify and capture potential customers interested in your offerings. We use a mix of digital marketing, content marketing, and targeted campaigns to generate leads that convert into loyal customers, driving your business growth.
      </Typography>
      <Grid container spacing={3}>
      
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
     
            <Typography variant="h4" gutterBottom>
              Advanced Techniques
            </Typography>
            <Typography variant="body1" paragraph>
              Our lead generation strategies leverage advanced digital marketing techniques such as SEO, SEM, email marketing, and social media advertising. We employ data-driven approaches to target and engage potential customers effectively.
            </Typography>
          
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
           
            <Typography variant="h4" gutterBottom>
              Targeted Campaigns
            </Typography>
            <Typography variant="body1" paragraph>
              We create customized lead generation campaigns tailored to your target audience and business objectives. By delivering the right message to the right people at the right time, we maximize lead quality and conversion rates.
            </Typography>
         
          </Paper>
        </Grid>
      </Grid>
    </Container>
    </div>
 
  )
}

export default LeadGeneration