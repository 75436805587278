import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import DrawerComp from "./DrawerComp";
import { Link as ScrollLink } from "react-scroll";
import styles from  "./Header.module.css"; // Import CSS file for styling
import curiologo from "../../assets/logo.png";

const Header = () => {
  const [activeLink, setActiveLink] = useState("home"); // State to track active link
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (link) => {
    setActiveLink(link); // Update active link state
  };

  return (
    <AppBar
      sx={{
        background: "white",
        color: "#000",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Toolbar position="sticky" sx={{ m: isMatch ? "0px" : "20px" }}>
        <Box display="flex" alignItems="center">
        <img src={curiologo} width={180} height={40} />
   
        </Box>

        {isMatch ? (
          <DrawerComp />
        ) : (
          <Box sx={{ display: "flex", marginLeft: "auto" }}>
            <ScrollLink
              to="home"
              smooth={true}
              offset={-70}
              duration={500}
              spy={true}
              activeClass="active" // Add activeClass prop
              onClick={() => handleChange("home")} // Pass the link name
              className={activeLink === "home" ? styles.active : ""}  // Add active class conditionally
            >
              <Typography variant="h6" sx={{ cursor: "pointer", transition: "color 0.3s" }}>
                Home
              </Typography>
            </ScrollLink>

            <ScrollLink
              to="services"
              smooth={true}
              offset={-70}
              duration={500}
              spy={true}
              activeClass="active"
              onClick={() => handleChange("services")}
              className={activeLink === "services" ? styles.active : ""}             
            >
              <Typography variant="h6" sx={{ cursor: "pointer", marginLeft: "20px", transition: "color 0.3s" }}>
                Services
              </Typography>
            </ScrollLink>
            <ScrollLink
              to="about"
              smooth={true}
              offset={-70}
              duration={500}
              spy={true}
              activeClass="active"
              onClick={() => handleChange("about")}
              className={activeLink === "about" ? styles.active : ""}             >
              <Typography variant="h6" sx={{ cursor: "pointer", marginLeft: "20px", transition: "color 0.3s" }}>
                About Us
              </Typography>
            </ScrollLink>
            <ScrollLink
              to="team"
              smooth={true}
              offset={-70}
              duration={500}
              spy={true}
              activeClass="active"
              onClick={() => handleChange("team")}
              className={activeLink === "team" ? styles.active : ""}     
            >
              <Typography variant="h6" sx={{ cursor: "pointer", marginLeft: "20px", transition: "color 0.3s" }}>
                Our Team
              </Typography>
            </ScrollLink>
            <ScrollLink
              to="footer"
              smooth={true}
              offset={-70}
              duration={500}
              spy={true}
              activeClass="active"
              onClick={() => handleChange("footer")}
              className={activeLink === "footer" ? styles.active : ""}     
            >
              <Typography variant="h6" sx={{ cursor: "pointer", marginLeft: "20px", transition: "color 0.3s" }}>
                Contact Us
              </Typography>
            </ScrollLink>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
