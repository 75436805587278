import React from 'react'
import BackHeader from '../backHeader/BackHeader'
import { Typography, Container, Grid, Paper } from "@mui/material";
const Outsourcing = () => {
  return (
    <div style={{marginTop:"100px"}}>
      <BackHeader/>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h2" gutterBottom>
        Outsourcing
      </Typography>
      <Typography variant="body1" paragraph>
        Optimize your operations and reduce costs with our outsourcing solutions. We provide skilled professionals and comprehensive services for tasks such as customer support, IT services, and administrative functions. By outsourcing to Curiostuff TechLab, you can focus on your core business activities while we handle the rest.
      </Typography>
      <Grid container spacing={3}>
    
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
              Skilled Professionals
            </Typography>
            <Typography variant="body1" paragraph>
              Our outsourcing solutions provide access to a pool of skilled professionals across various domains, including customer support, IT services, and administration. We ensure that you receive top-notch expertise to meet your business needs.
            </Typography>
            
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
           
            <Typography variant="h4" gutterBottom>
              Focus on Core Business
            </Typography>
            <Typography variant="body1" paragraph>
              By outsourcing tasks to Curiostuff TechLab, you can redirect your resources and attention to core business activities, such as innovation, growth, and strategy. Let us handle the operational aspects while you concentrate on driving business success.
            </Typography>
           
          </Paper>
        </Grid>
      </Grid>
    </Container>
    </div>
   
  )
}

export default Outsourcing