import React from "react";
import BackHeader from "../backHeader/BackHeader";
import { Typography, Container, Grid, Paper } from "@mui/material";
const WebApplicationDevelopment = () => {
  return (
    <div style={{ marginTop: "100px" }}>
      <BackHeader />
      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Typography variant="h2" gutterBottom>
          Web Application Development
        </Typography>
        <Typography variant="body1" paragraph>
          Transform your ideas into powerful web applications with our
          development services. We design and develop custom web applications
          tailored to your business needs. Our team ensures that your web
          applications are user-friendly, scalable, and secure, providing a
          seamless experience for your users.
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h4" gutterBottom>
                Custom Development
              </Typography>
              <Typography variant="body1" paragraph>
                We specialize in custom web application development, creating
                solutions that meet your unique requirements and goals. Our team
                works closely with you to understand your business needs and
                deliver tailored web applications that drive growth and success.
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h4" gutterBottom>
                User-Friendly Design
              </Typography>
              <Typography variant="body1" paragraph>
                Our focus on user experience ensures that your web applications
                are intuitive and easy to navigate. We design interfaces that
                prioritize usability and accessibility, providing a seamless
                experience for your users across all devices.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default WebApplicationDevelopment;
