import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import Helpus from "../../assets/images/help/help.jpg";
import StyledButton from "../../components/button/StyledButton";

const ServicesMain = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box>
      <Grid
        container
        spacing={isMobile ? 2 : 10}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={6}>
          <Typography
            variant={isMobile ? "h3" : "h3"}
            align={isMobile ? "center" : "inherit"}
            mb={isMobile ? 2 : 6}
            sx={{
              fontSize: { xs: "2rem", md: "3rem" },
              fontWeight: "bold",
              marginBottom: 2,
            }}
          >
            We Help You
            <pre>
              <span style={{ fontWeight: "bold" }}>To </span>
              <span style={{ fontWeight: "500" }}>Grow Your</span>
            </pre>
            <span style={{ fontWeight: "500" }}>Business</span>
          </Typography>

          <Typography
            variant="body1"
            align={isMobile ? "center" : "inherit"}
            mb={isMobile ? 2 : 6}
          >
            At Curiostuff TechLab, we offer a comprehensive suite of services
            designed to meet the diverse needs of modern businesses. Our
            expertise spans from digital marketing to application development
            and support, ensuring that we can provide end-to-end solutions for
            your business growth and technological advancement.
          </Typography>
          <Box display="flex" justifyContent={isMobile ? "center" : "inherit"}>
            <StyledButton>Our Services</StyledButton>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={Helpus}
            alt="Curiostuff Home"
            sx={{
              width: "100%",
              borderRadius: isMobile ? "0" : "4%",
              border: isMobile ? "none" : "5px solid #fff",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ServicesMain;
