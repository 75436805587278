import React from 'react'
import BackHeader from '../backHeader/BackHeader'
import { Typography, Container, Grid, Paper } from "@mui/material";

const DesktopApplicationDevelopment = () => {
  return (
    <div style={{marginTop:"100px"}}>
<BackHeader/>
<Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h2" gutterBottom>
        Desktop Application Development
      </Typography>
      <Typography variant="body1" paragraph>
        Enhance your business operations with our desktop application development services. We build robust and efficient desktop applications that cater to your specific requirements. Our support services ensure that your applications are maintained, updated, and performing optimally at all times.
      </Typography>
      <Grid container spacing={3}>
  
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
              Windows App Development
            </Typography>
            <Typography variant="body1" paragraph>
              We specialize in developing desktop applications for the Windows platform. Our Windows apps are built using industry-standard technologies and frameworks, ensuring compatibility, security, and performance.
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
              macOS App Development
            </Typography>
            <Typography variant="body1" paragraph>
              Our macOS app development services focus on creating native desktop applications for Apple's macOS operating system. We leverage the latest macOS technologies to deliver seamless experiences for Mac users.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
    </div>
  
  )
}

export default DesktopApplicationDevelopment