import React from 'react'
import BackHeader from '../backHeader/BackHeader'
import { Typography, Container, Grid, Paper } from "@mui/material";
const ExistingApplicationServices = () => {
  return (
    <div style={{marginTop:"100px"}}>
      <BackHeader/>
      <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h2" gutterBottom>
        Existing Application Services
      </Typography>
      <Typography variant="body1" paragraph>
        Maximize the value of your existing applications with our maintenance and enhancement services. We offer ongoing support to ensure your applications run smoothly and efficiently. Our team can also enhance functionality, update features, and integrate new technologies to keep your applications current and competitive.
      </Typography>
      <Grid container spacing={3}>
     
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom>
              Ongoing Support
            </Typography>
            <Typography variant="body1" paragraph>
              Our maintenance services include ongoing support to address any issues or challenges that arise with your existing applications. We provide timely assistance to ensure minimal disruptions and maximum uptime for your business-critical applications.
            </Typography>
          
          </Paper>
        </Grid>
   
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
 
            <Typography variant="h4" gutterBottom>
              Enhancement Services
            </Typography>
            <Typography variant="body1" paragraph>
              In addition to maintenance, our team offers enhancement services to improve the functionality and performance of your existing applications. We can update features, integrate new technologies, and optimize workflows to keep your applications current and competitive in the market.
            </Typography>
          
          </Paper>
        </Grid>
      </Grid>
    </Container>
    </div>
  
  )
}

export default ExistingApplicationServices