import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#000000',
  color: '#ffffff',
  padding: '10px 20px',
  borderRadius: '5px',
  textTransform: 'none',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#333333',
  },
}));

const StyledButton = ({ children, ...props }) => {
  return (
    <CustomButton {...props}>
      {children}
    </CustomButton>
  );
};

export default StyledButton;
