import React from "react";
import "./App.css";
import Footer from "./components/footer/footer";
import Header from "./components/header/Header";
import Testimonials from "./components/rating/Testimonials";
import About from "./screens/about/About";
import Home from "./screens/home/Home";
import ServicesCardList from "./screens/services/ServicesCardList";
import Support from "./screens/support/Support";
import Terms from "./components/terms/Terms";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Policy from "./components/policy/Policy";
import InfluenceMarketing from "./components/serviceCard/InfluenceMarketing";
import Outsourcing from "./components/serviceCard/Outsourcing";
import SocialMediaMarketing from "./components/serviceCard/SocialMediaMarketing";
import LeadGeneration from "./components/serviceCard/LeadGeneration";
import ExistingApplicationServices from "./components/serviceCard/ExistingApplicationServices";
import WebApplicationDevelopment from "./components/serviceCard/WebApplicationDevelopment";
import MobileApplicationDevelopment from "./components/serviceCard/MobileApplicationDevelopment";
import DesktopApplicationDevelopment from "./components/serviceCard/DesktopApplicationDevelopment";

function App() {
  return (
    <Router>
      <React.Fragment>
        <Routes>
          <Route
            path="/"
            element={
              <React.Fragment>
                <section>
                  <Header />
                </section>
                <section id="home">
                  <Home />
                </section>
                <section id="services">
                  <ServicesCardList />
                </section>
                <section id="team">
                  <Testimonials />
                </section>
                <section id="about">
                  <About />
                </section>
                <section id="contact">
                  <Support />
                </section>
                <footer id="footer">
                  <Footer />
                </footer>
              </React.Fragment>
            }
          />

          <Route path="/influence-marketing" element={<InfluenceMarketing />} />
          <Route
            path="/socialmedia-marketing"
            element={<SocialMediaMarketing />}
          />
          <Route path="/lead-generation" element={<LeadGeneration />} />

          <Route path="/outsourcing" element={<Outsourcing />} />
          <Route
            path="/existing-application-services"
            element={<ExistingApplicationServices />}
          />
          <Route
            path="/web-application-development"
            element={<WebApplicationDevelopment />}
          />

          <Route
            path="/mobile-application-development"
            element={<MobileApplicationDevelopment />}
          />
          <Route
            path="/desktop-application-development"
            element={<DesktopApplicationDevelopment />}
          />

          <Route path="/about-us" element={<About />} />

          <Route path="/privacy-policy" element={<Policy />} />
          <Route path="/terms" element={<Terms/>} />

        </Routes>
      </React.Fragment>
    </Router>
  );
}

export default App;
